<template lang="pug">
  .input-number
    input.form-control(type="number" v-model="localValue" :step="step")
</template>
<script>
export default {
  name: 'InputNumber',
  props: {
    value: {type: String, default: ''},
    step: {type: Number, default: 1},
  },
  data () {
    return {
      localValue: '',
    }
  },
  beforeMount() {
    this.localValue = this.value
  },
  watch: {
      localValue (newValue) {
       this.$emit('input', newValue)
     },
     value (newValue) {
       this.localValue = newValue
     }
  }
}
</script>
<style scoped>
</style>