<template lang="pug">
  .doctor-show
    .header-actions(v-if="doctor.id")
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
            
    .main-content(v-if="doctor.id")
      .p-2
        .container-fluid
          .row.mb-2
            .col-12
              .row
                .col-3
                  .card
                    .card-body
                      h5.card-title.mb-0 {{doctor.name}}
                      span {{doctor.doctor_type.name}}
                      .table-responsive.mt-3
                        table.table.table-striped
                          tbody
                            tr
                              td
                                strong {{$t('LLamadas de la semana')}}
                              td.text-end {{totalCalls.total_week}}
                            tr
                              td
                                strong {{$t('LLamadas del mes')}}
                              td.text-end {{totalCalls.total_month}}
                            tr
                              td
                                strong {{$t('LLamadas del año')}}
                              td.text-end {{totalCalls.total_year}}
                .col-9
                  .card
                    .card-body
                      .d-flex
                        .flex-grow-1.p-2
                          h5.card-title {{$t('Listado de desvíos')}}
                        .p-2
                      .table-responsive
                        table.table.table-striped
                          thead
                            tr
                              th {{$t('Nombre')}}
                              th {{$t('Ddi')}}
                              th {{$t('Redirección')}}
                              th {{$t('Idioma')}}
                              th {{$t('Email 1')}}
                              th {{$t('Email 2')}}
                          tbody
                            tr(v-for="forward in doctor.forwards")
                              td {{forward.name}}
                              td {{forward.ddi.ddi}}
                              td {{forward.redirect}}
                              td {{forward.language.name}}
                              td {{forward.email1}}
                              td {{forward.email2}}
      template(v-if="doctor.id")
        call-list-account(:doctor_id="doctor.id")
</template>

<script>
  import CallListAccount from '../../components/calls/ListAccount.vue'
  export default {
    name: 'DoctorShow',
    components: {
      CallListAccount
    },
    beforeMount() {
      this.$store.dispatch("getRegister", {
        moduleUrl: 'doctors',
        id: 'me'
      }).then((res) => {
        this.doctor = res
        this.forward.doctor_id = res.id

        this.$store.dispatch("totalDoctorCalls", {
          doctorId: this.doctor.id
        }).then((res2) => {
          this.totalCalls = res2
        })
      })


    },
    data () {
      return {
        doctor: {},
        forwardModule: {},
        forward: {},
        totalCalls: {
          total_week: 0,
          total_month: 0,
          total_year: 0
        }
      }
    },
    methods: {
      
    }
  }
</script>