<template lang="pug">
  .call-lists
    .header-actions
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
          .p-2(v-if="!showFilter")
            input.form-control(type="date" v-model="start")
          .p-2(v-if="!showFilter")
            input.form-control(type="date" v-model="end")
          .p-2
            .input-group
              button.btn.btn-outline-secondary(type='button' @click="prevPage()" v-if="page > 1")
                font-awesome-icon(icon='angle-double-left')
              select.form-select.border-secondary(aria-label='Default select example' v-model="page" @change="search()")
                option(v-for='i in totalPages' :key="'page' + i" :value='i') {{i}}
              button.btn.btn-outline-secondary(type='button' @click="nextPage()" v-if="page < totalPages")
                font-awesome-icon(icon='angle-double-right')
          .p-2
            .btn-group
              button.btn(type="button" @click="search" :class="showFilter ? 'btn-primary' : 'btn-outline-primary'")
                font-awesome-icon.me-2(icon="search")
                span Buscar
              button.btn(type="button" @click="toggleFilter" :class="showFilter ? 'btn-primary' : 'btn-outline-primary'")
                font-awesome-icon.me-2(icon="filter")
                span Avanzado
              button.btn.btn-outline-success(type="button" @click="exportCalls")
                font-awesome-icon.me-2(icon="download")
                span Exportar
    .main-content
      .p-2
        .container-fluid
          .row.mb-2(v-if="showFilter")
            .col-12
              .card
                .card-body
                  .row.mb-2
                    .col-12
                      h5.card-title Filtro
                  .row.mb-2
                    .col-3
                      label.label-control Desde
                      input.form-control(type="date" v-model="start")
                    .col-3
                      label.label-control Hasta
                      input.form-control(type="date" v-model="end")
                  .row.mb-2
                    .col-3
                      label.label-control Doctor
                      v-select(:options="doctors" :reduce="label => label.id"  label="label" v-model="doctor_id" @search="doctorSearch")
                    .col-3
                      label.label-control Número Top Doctors
                      v-select(:options="ddis" :reduce="label => label.id"  label="label" v-model="ddi_id" @search="ddiSearch")
                    .col-3
                      label.label-control Número del doctor
                      v-select(:options="forwards" :reduce="label => label.id"  label="label" v-model="forward_id" @search="forwardSearch")
                    .col-3
                      label.label-control Número del paciente
                      input.form-control(type="text" v-model="callerid")
                  .row.mb-2
                    .col-3
                      label.label-control Aplicación
                      v-select(:options="call_applications" :reduce="label => label.id"  label="label" v-model="call_application_id")
                    .col-3
                      label.label-control Idioma
                      v-select(:options="languages" :reduce="label => label.id"  label="label" v-model="language_id")
                    .col-3
                      label.label-control Mostrar exclusión
                      v-select(:options="[{id: 0, label: 'No'}, {id: 1, label: 'Si'}]" :reduce="label => label.id"  label="label" v-model="exclude")
                  
          .row
            .col-12
              .card
                .card-body
                  .row
                    .col-6
                      h5.card-title Llamadas
                    .col-6
                      p.text-end 
                        span Mostrando 
                        strong {{limitStart}} - {{limitEnd}}
                        span &nbsp;de&nbsp; 
                        strong {{totalElements}}
                  .table-responsive
                    table.table.table-striped.table-hover
                      thead
                        tr
                          th(@click="changeSortDirection('call_status')" ) 
                            template(v-if="sortColumn == 'call_status'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span Estado
                          th(@click="changeSortDirection('doctor')" ) 
                            template(v-if="sortColumn == 'doctor'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span Doctor
                          th(@click="changeSortDirection('ddi')" ) 
                            template(v-if="sortColumn == 'ddi'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span Número Top Doctors
                          th(@click="changeSortDirection('forward')" ) 
                            template(v-if="sortColumn == 'forward'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span Desvío
                          th(@click="changeSortDirection('callerid')" ) 
                            template(v-if="sortColumn == 'callerid'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span Número paciente
                          th(@click="changeSortDirection('start')" ) 
                            template(v-if="sortColumn == 'start'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span Fecha de inicio
                          th(@click="changeSortDirection('duration')" ) 
                            template(v-if="sortColumn == 'duration'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span Duración
                          th(@click="changeSortDirection('application')" ) 
                            template(v-if="sortColumn == 'application'")
                              font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                              font-awesome-icon.float-end(icon="angle-down" v-else)
                            span Aplicación
                          th
                      tbody
                        tr(v-for="register in registers")
                          td {{register.call_status}}
                          td {{register.doctor}}
                          td {{register.ddi}}
                          td {{register.forward}}
                          td {{register.callerid}}
                          td {{register.start | unixToEnglishDate }}
                          td {{register.duration | timeInHours}}
                          td {{register.application}}
                          td
                            button.btn.btn-text(v-if="register.email_notification" type="button" data-bs-toggle="tooltip" data-bs-placement="top" :title="register.email_notification")
                              font-awesome-icon(icon="envelope")
                            button.btn.btn-text(v-if="register.sms_notification" type="button" data-bs-toggle="tooltip" data-bs-placement="top" :title="register.sms_notification")
                              font-awesome-icon(icon="sms")
</template>

<script>
  import jQuery from 'jquery';
  export default {
    name: 'CallList',
    data() {
      return {
        currentModule: {},
        page: 1,
        registers: [],
        totalPages: 1,
        totalElements: 0,
        sortColumn: 'start',
        sortDirection: -1,
        limit: 25,
        limitStart: 0,
        limitEnd: 0,
        doctors: [],
        ddis: [],
        forwards: [],
        call_applications: [],
        languages: [],
        start: null,
        end: null,
        doctor_id: null,
        ddi_id: null,
        forward_id: null,
        callerid: '',
        exclude: 1,
        call_application_id: null,
        language_id: null,
        showFilter: false
      }
    },
    beforeMount() {
      this.$store.dispatch('getRegistersList', {
          moduleUrl: 'call_applications',
          columns: 'id,name'
        }).then((res) => {
          this.call_applications = res
      })
      this.$store.dispatch('getRegistersList', {
          moduleUrl: 'languages',
          columns: 'id,name'
        }).then((res) => {
          this.languages = res
      })
      this.search()
    },
    watch: {
      
    },
    directives: {
      hover: ((el, binding) => {
        jQuery(el).tooltip({
           title: binding.value,
           placement: binding.arg,
           trigger: 'hover'             
       })  
      })

    },
    methods: {
      toggleFilter() {
        this.showFilter = !this.showFilter
      },
      search() {
        this.$store.dispatch("searchCalls", {
          page: this.page,
          data: {
            filter: this.filter,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            limit: this.limit,
            start: this.start,
            end: this.end,
            doctor_id: this.doctor_id,
            ddi_id: this.ddi_id,
            forward_id: this.forward_id,
            call_application_id: this.call_application_id,
            language_id: this.language_id,
            exclude: this.exclude,
            callerid: this.callerid,
          }
        }).then((res) => {
          this.registers = res.data
          this.totalPages = res.total_pages
          this.limit = res.limit
          this.limitStart = res.limit_start + 1
          this.limitEnd = res.limit_start + res.limit
          this.totalElements = res.total
          if(this.limitEnd > this.totalElements) {
            this.limitEnd = this.totalElements
          }

          
        })
      },
      exportCalls() {
        this.$store.dispatch("exportCalls", {
          data: {
            filter: this.filter,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            start: this.start,
            end: this.end,
            doctor_id: this.doctor_id,
            ddi_id: this.ddi_id,
            forward_id: this.forward_id,
            call_application_id: this.call_application_id,
            language_id: this.language_id,
            exclude: this.exclude,
            callerid: this.callerid,
          }
        }).then((res) => {
          let path = res.path
          
          window.location.replace(path)
          
        })
      },
      prevPage() {
        this.page--
        this.search();
      },
      nextPage() {
        this.page++
        this.search();
      },
      changeSortDirection(column) {
        if(column == this.sortColumn) {
          this.sortDirection = this.sortDirection * -1
        } else {
          this.sortColumn = column
          this.sortDirection = 1
        }

        this.search()
      },
      doctorSearch(search, loading) {
        let filter = {
            filter: search,
            moduleUrl: 'doctors',
            columns: 'id,name',
            conditions: ''
        }
        loading(true)
        this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
            this.doctors = res
            loading(false)
        })
      },
      ddiSearch(search, loading) {
        let filter = {
            filter: search,
            moduleUrl: 'ddis',
            columns: 'id,ddi',
            conditions: ''
        }
        loading(true)
        this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
            this.ddis = res
            loading(false)
        })
      },
      forwardSearch(search, loading) {
        let filter = {
            filter: search,
            moduleUrl: 'forwards',
            columns: 'id,redirect',
            conditions: ''
        }
        loading(true)
        this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
            this.forwards = res
            loading(false)
        })
      },
    }
  }
</script>