<template lang="pug">
  .input-select
    template(v-if="isAjax")
      v-select(:options="options" :reduce="label => label.id"  label="label" v-model="localValue" @search="ajaxSearch")
    template(v-else)
      v-select(:options="options" :reduce="label => label.id"  label="label" v-model="localValue")
</template>
<script>
export default {
  name: 'InputSelect',
  props: {
    value: {default: ''},
    moduleOptions: {type: String, default: ''},
    relatedKey: {type: String, default: ''},
    relatedValue: {type: String, default: ''},
  },
  beforeMount() {
    let parts = this.moduleOptions.split(':')
    this.conditions = ''
    if(this.relatedKey && this.relatedValue) {
      this.conditions = this.relatedKey + ':' + this.relatedValue
    } else if(parts[3]) {
      this.conditions = parts[3]
    }

    if(parts[0] == 'table') {
      this.$store.dispatch("getRegistersList", {
        moduleUrl: parts[1],
        columns: parts[2],
        conditions: this.conditions
      }).then((res) => {
        this.options = res
      })
    } else if(parts[0] == 'ajax') {
      this.isAjax = true
      this.ajaxModuleUrl = parts[1],
      this.ajaxModuleColumns = parts[2]

      if(this.value && this.options.findIndex(o => o.id == this.value) == -1) {
        this.$store.dispatch("getRegister", {
          moduleUrl: this.ajaxModuleUrl,
          id: this.value
        }).then((res) => {
          let columns = parts[2].split(",")
          this.options.push({id: res[columns[0]], label: res[columns[1]] })
        })
      }
    } else {
      let elements = this.moduleOptions.split("\n")
      this.options = {}
      elements.forEach((element) => {
        let valueLabel = element.split(":")
        this.options[valueLabel[0]] = valueLabel[1]
      })
    }
  
    this.localValue = this.value
  },
  data () {
    return {
      localValue: '',
      options: [],
      isAjax: false,
      ajaxModuleUrl: '',
      ajaxModuleColumns: '',
      conditions: '',
    }
  },
  watch: {
    localValue (newValue) {
      this.$emit('input', newValue)
    },
    value (newValue) {
      let parts = this.moduleOptions.split(':')
      this.localValue = newValue

      if(newValue) {
        if(this.isAjax && this.options.findIndex(o => o.id == newValue) == -1) {
          this.$store.dispatch("getRegister", {
            moduleUrl: parts[1],
            id: newValue
          }).then((res) => {
            let columns = parts[2].split(",")
            this.options.push({id: res[columns[0]], label: res[columns[1]] })
          })
        }
      }
    },
    relatedValue(newValue) {
      let parts = this.moduleOptions.split(':')
      this.conditions = ''
      if(this.relatedKey && newValue) {
        this.conditions = this.relatedKey + '=' + newValue
      } else if(parts[3]) {
        this.conditions = parts[3]
      }

      if(parts[0] == 'table') {
        this.$store.dispatch("getRegistersList", {
          moduleUrl: parts[1],
          columns: parts[2],
          conditions: this.conditions
        }).then((res) => {
          this.options = res
        })
      } else if(parts[0] == 'ajax') {
        this.isAjax = true
        this.ajaxModuleUrl = parts[1],
        this.ajaxModuleColumns = parts[2]
      } else {
        let elements = this.moduleOptions.split("\n")
        this.options = {}
        elements.forEach((element) => {
          let valueLabel = element.split(":")
          this.options[valueLabel[0]] = valueLabel[1]
        })
      }
    }
  },
  methods: {
    ajaxSearch(search, loading) {
      if(this.isAjax) {
          let filter = {
              filter: search,
              moduleUrl: this.ajaxModuleUrl,
              columns: this.ajaxModuleColumns,
              conditions: this.conditions
          }
          loading(true)
          this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
              this.options = res
              loading(false)
          })
      }
    },
  }
}
</script>
<style scoped>
</style>