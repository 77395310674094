<template lang="pug">
  .input-multi-select
    v-select(:options="options" :reduce="label => label.id"  label="label" v-model="localValue" multiple="multiple")
</template>
<script>
export default {
  name: 'InputMultiSelect',
  props: {
    value: {type: Array, default: function() { return []}},
    moduleOptions: {type: String, default: ''}
  },
  beforeMount() {
    let parts = this.moduleOptions.split(':')
    if(parts[0] == 'table') {
      this.$store.dispatch("getRegistersList", {
        moduleUrl: parts[1],
        columns: parts[2]
      }).then((res) => {
        this.options = res
      })
    } else {
      let elements = this.moduleOptions.split("\n")
      this.options = {}
      elements.forEach((element) => {
        let valueLabel = element.split(":")
        this.options[valueLabel[0]] = valueLabel[1]
      })
    }
  
    this.localValue = this.value
  },
  data () {
    return {
      localValue: '',
      options: []
    }
  },
  watch: {
      localValue (newValue) {
       this.$emit('input', newValue)
     },
     value (newValue) {
       this.localValue = newValue
     }
  }
}
</script>
<style scoped>
</style>