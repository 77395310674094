<template lang="pug">
  .call-lists-account
    .p-2
      .container-fluid
        .row.mb-2
          .col-12
            .card
              .card-body
                .row.mb-2
                  .col-12
                    h5.card-title {{$t('Filtro')}}
                .row.mb-2
                  .col-3
                    label.label-control {{$t('Desde')}}
                    input.form-control(type="date" v-model="start" @keyup.enter="search()")
                  .col-3
                    label.label-control {{$t('Hasta')}}
                    input.form-control(type="date" v-model="end" @keyup.enter="search()")
                .row.mb-2
                  .col-3
                    label.label-control {{$t('Número de Top doctors')}}
                    v-select(:options="ddis" :reduce="label => label.id"  label="label" v-model="ddi_id" @search="ddiSearch")
                  .col-3
                    label.label-control {{$t('Número del doctor')}}
                    v-select(:options="forwards" :reduce="label => label.id"  label="label" v-model="forward_id" @search="forwardSearch")
                  .col-3
                    label.label-control {{$t('Paciente')}}
                    input.form-control(type="text" v-model="callerid" @keyup.enter="search()")
                .row.mb-2
                  .col-3
                    label.label-control {{$t('Aplicación')}}
                    v-select(:options="call_applications" :reduce="label => label.id"  label="label" v-model="call_application_id")
                  .col-3
                    label.label-control {{$t('Idioma')}}
                    v-select(:options="languages" :reduce="label => label.id"  label="label" v-model="language_id")
                .row.mb-2
                  .col-12
                    .d-flex
                      .flex-grow-1.p-2
                        .input-group
                          button.btn.btn-primary(@click="search()") 
                            font-awesome-icon.me-2(icon="search")
                            span {{$t('Buscar')}}
                          button.btn.btn-outline-success(type="button" @click="exportCalls")
                            font-awesome-icon.me-2(icon="download")
                            span {{$t('Exportar')}}
                      .p-2
                        .input-group
                          button.btn.btn-outline-secondary(type='button' @click="prevPage()" v-if="page > 1")
                            font-awesome-icon(icon='angle-double-left')
                          select.form-select.border-secondary(aria-label='Default select example' v-model="page" @change="search()")
                            option(v-for='i in totalPages' :key="'page' + i" :value='i') {{i}}
                          button.btn.btn-outline-secondary(type='button' @click="nextPage()" v-if="page < totalPages")
                            font-awesome-icon(icon='angle-double-right')
        .row
          .col-12
            .card
              .card-body
                .row
                  .col-6
                    h5.card-title {{$t('Llamadas')}}
                  .col-6
                    p.text-end 
                      span {{$t('Mostrando')}} 
                      strong {{limitStart}} - {{limitEnd}}
                      span &nbsp;de&nbsp; 
                      strong {{totalElements}}
                .table-responsive
                  table.table.table-striped.table-hover
                    thead
                      tr
                        th(@click="changeSortDirection('call_status')" ) 
                          template(v-if="sortColumn == 'call_status'")
                            font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                            font-awesome-icon.float-end(icon="angle-down" v-else)
                          span {{$t('Estado')}}
                        th(@click="changeSortDirection('doctor')") 
                          template(v-if="sortColumn == 'doctor'")
                            font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                            font-awesome-icon.float-end(icon="angle-down" v-else)
                          span {{$t('Doctor')}}
                        th(@click="changeSortDirection('ddi')" ) 
                          template(v-if="sortColumn == 'ddi'")
                            font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                            font-awesome-icon.float-end(icon="angle-down" v-else)
                          span {{$t('Número Top Doctors')}}
                        th(@click="changeSortDirection('forward')" ) 
                          template(v-if="sortColumn == 'forward'")
                            font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                            font-awesome-icon.float-end(icon="angle-down" v-else)
                          span {{$t('Desvío')}}
                        th(@click="changeSortDirection('callerid')" ) 
                          template(v-if="sortColumn == 'callerid'")
                            font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                            font-awesome-icon.float-end(icon="angle-down" v-else)
                          span {{$t('Número paciente')}}
                        th(@click="changeSortDirection('start')" ) 
                          template(v-if="sortColumn == 'start'")
                            font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                            font-awesome-icon.float-end(icon="angle-down" v-else)
                          span {{$t('Fecha de inicio')}}
                        th(@click="changeSortDirection('duration')" ) 
                          template(v-if="sortColumn == 'duration'")
                            font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                            font-awesome-icon.float-end(icon="angle-down" v-else)
                          span {{$t('Duración')}}
                        th(@click="changeSortDirection('application')" ) 
                          template(v-if="sortColumn == 'application'")
                            font-awesome-icon.float-end(icon="angle-up" v-if="sortDirection == 1")
                            font-awesome-icon.float-end(icon="angle-down" v-else)
                          span {{$t('Aplicación')}}
                        th
                    tbody
                      tr(v-for="register in registers")
                        td {{register.call_status}}
                        td {{register.doctor}}
                        td {{register.ddi}}
                        td {{register.forward}}
                        td {{register.callerid}}
                        td {{register.start | unixToEnglishDate}}
                        td {{register.duration | timeInHours}}
                        td {{register.application}}
                        td
                          button.btn.btn-text(v-if="register.email_notification" type="button" data-bs-toggle="tooltip" data-bs-placement="top" :title="register.email_notification")
                            font-awesome-icon(icon="envelope")
                          button.btn.btn-text(v-if="register.sms_notification" type="button" data-bs-toggle="tooltip" data-bs-placement="top" :title="register.sms_notification")
                            font-awesome-icon(icon="sms")
</template>

<script>
  export default {
    name: 'CallListAccount',
    props: {
      doctor_id: {type: Number, default: 0},
    },
    data() {
      return {
        currentModule: {},
        page: 1,
        registers: [],
        totalPages: 1,
        totalElements: 0,
        sortColumn: 'start',
        sortDirection: -1,
        limit: 25,
        limitStart: 0,
        limitEnd: 0,
        ddis: [],
        forwards: [],
        call_applications: [],
        languages: [],
        start: null,
        end: null,
        callerid: '',
        ddi_id: null,
        forward_id: null,
        call_application_id: null,
        language_id: null,
        showFilter: false
      }
    },
    beforeMount() {
      this.$store.dispatch('getRegistersList', {
          moduleUrl: 'call_applications',
          columns: 'id,name'
        }).then((res) => {
          this.call_applications = res
      })
      this.$store.dispatch('getRegistersList', {
          moduleUrl: 'languages',
          columns: 'id,name'
        }).then((res) => {
          this.languages = res
      })
      
      this.search()
      this.ddiSearch('', function(){})
      this.forwardSearch('', function(){})
    },
    watch: {
      doctor_id() {
        this.search()
        this.ddiSearch('', function(){})
        this.forwardSearch('', function(){})
      }
    },
    methods: {
      toggleFilter() {
        this.showFilter = !this.showFilter
      },
      search() {
        this.$store.dispatch("searchCalls", {
          page: this.page,
          data: {
            filter: this.filter,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            limit: this.limit,
            start: this.start,
            end: this.end,
            doctor_id: this.doctor_id,
            ddi_id: this.ddi_id,
            forward_id: this.forward_id,
            call_application_id: this.call_application_id,
            language_id: this.language_id,
            callerid: this.callerid,
          }
        }).then((res) => {
          this.registers = res.data
          this.totalPages = res.total_pages
          this.limit = res.limit
          this.limitStart = res.limit_start + 1
          this.limitEnd = res.limit_start + res.limit
          this.totalElements = res.total
          if(this.limitEnd > this.totalElements) {
            this.limitEnd = this.totalElements
          }
        })
      },
      exportCalls() {
        this.$store.dispatch("exportCalls", {
          data: {
            filter: this.filter,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection,
            start: this.start,
            end: this.end,
            doctor_id: this.doctor_id,
            ddi_id: this.ddi_id,
            forward_id: this.forward_id,
            call_application_id: this.call_application_id,
            language_id: this.language_id,
            exclude: this.exclude,
            callerid: this.callerid,
          }
        }).then((res) => {
          let path = res.path
          
          window.location.replace(path)
          
        })
      },
      prevPage() {
        this.page--
        this.search();
      },
      nextPage() {
        this.page++
        this.search();
      },
      changeSortDirection(column) {
        if(column == this.sortColumn) {
          this.sortDirection = this.sortDirection * -1
        } else {
          this.sortColumn = column
          this.sortDirection = 1
        }

        this.search()
      },
      ddiSearch(search, loading) {
        let filter = {
            filter: search,
            moduleUrl: 'ddis',
            columns: 'id,ddi',
            conditions: 'forwards.doctor_id=' + this.doctor_id
        }
        loading(true)
        this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
            this.ddis = res
            loading(false)
        })
      },
      forwardSearch(search, loading) {
        let filter = {
            filter: search,
            moduleUrl: 'forwards',
            columns: 'id,redirect',
            conditions: 'doctor_id=' + this.doctor_id
        }
        loading(true)
        this.$store.dispatch('getRegistersListAjax', filter).then((res) => {
            this.forwards = res
            loading(false)
        })
      },
    }
  }
</script>