<template lang="pug">
  .doctor-show
    .header-actions(v-if="doctor.id")
      .header-actions-content
        .d-flex
          .flex-grow-1.p-2
            
          .p-2
            .btn-group.me-2
              a.btn.btn-outline-secondary(type='button' href="/doctors")
                font-awesome-icon.me-2(:icon="['fas', 'arrow-left']")
                span {{$t('Volver')}}
            .btn-group.me-2
              a.btn.btn-outline-primary(type='button' :href="'/doctors/' + doctor.id + '/edit'")
                font-awesome-icon.me-2(:icon="['fas', 'edit']")
                span {{$t('Editar')}}
            .btn-group.me-2
              button.btn.btn-outline-danger(type='button' @click="deleteAccount()")
                font-awesome-icon.me-2(:icon="['fas', 'trash']")
                span {{$t('Eliminar')}}
    .main-content(v-if="doctor.id")
      .p-2
        .container-fluid
          .row.mb-2
            .col-12
              .row
                .col-3
                  .card
                    .card-body
                      h5.card-title.mb-0 {{doctor.name}}
                      span {{doctor.doctor_type.name}}
                      .table-responsive.mt-3
                        table.table.table-striped
                          tbody
                            tr
                              td
                                strong {{$t('LLamadas de la semana')}}
                              td.text-end {{totalCalls.total_week}}
                            tr
                              td
                                strong {{$t('LLamadas del mes')}}
                              td.text-end {{totalCalls.total_month}}
                            tr
                              td
                                strong {{$t('LLamadas del año')}}
                              td.text-end {{totalCalls.total_year}}
                .col-9
                  .card
                    .card-body
                      .d-flex
                        .flex-grow-1.p-2
                          h5.card-title {{$t('Listado de desvíos')}}
                        .p-2
                          .btn-group.me-2
                            button.btn.btn-outline-primary(type='button' data-bs-toggle='modal' data-bs-target='#forwardModal')
                              font-awesome-icon.me-2(icon="plus-circle")
                              span {{$t('Añadir')}}
                      .table-responsive
                        table.table.table-striped
                          thead
                            tr
                              th {{$t('Nombre')}}
                              th {{$t('Ddi')}}
                              th {{$t('Redirección')}}
                              th {{$t('Idioma')}}
                              th {{$t('Email 1')}}
                              th {{$t('Email 2')}}
                              th
                          tbody
                            tr(v-for="forward in doctor.forwards")
                              td {{forward.name}}
                              td {{forward.ddi.ddi}}
                              td {{forward.redirect}}
                              td {{forward.language.name}}
                              td {{forward.email1}}
                              td {{forward.email2}}
                              td
                                button.btn.btn-link.text-dark(type='button' data-bs-toggle='modal' data-bs-target='#forwardModal' @click="editForward(forward)")
                                  font-awesome-icon(icon="edit")
      template(v-if="doctor.id")
        call-list-account(:doctor_id="doctor.id")
      #forwardModal.modal(v-if="forward" tabindex='-1' aria-hidden="true") 
        .modal-dialog.modal-lg
          .modal-content
            .modal-header
              h5.modal-title {{$t('Desvío')}}
              button#forwardModalClose.btn-close(type='button' data-bs-dismiss='modal' aria-label='Close')
            .modal-body
              draw-simp-form(
                :module="forwardModule" 
                :value="forward"
                @createRegister="createForward"
                @updateRegister="updateForward"
              )
</template>

<script>
  import CallListAccount from '../../components/calls/ListAccount.vue'
  import jQuery from 'jquery'
  import DrawSimpForm from '../../components/forms/DrawSimp.vue'
  export default {
    name: 'DoctorShow',
    components: {
      CallListAccount,
      DrawSimpForm
    },
    beforeMount() {
      this.$store.dispatch("getRegister", {
        moduleUrl: 'doctors',
        id: this.$route.params.id
      }).then((res) => {
        this.doctor = res
        this.forward.doctor_id = res.id

        this.$store.dispatch("totalDoctorCalls", {
          doctorId: this.doctor.id
        }).then((res2) => {
          this.totalCalls = res2
        })
      })

      this.$store.dispatch("getModuleByUrl", {moduleUrl: 'forwards'})
        .then((res) => {
          res.module_tabs.forEach((tab, indexTab) => {
            tab.module_sections.forEach((section, indexSection) => {
              let indexField = section.module_fields.findIndex(x => x.name == 'doctor_id')

              if(indexField > -1) {
                let moduleField = section.module_fields[indexField]

                this.$set(moduleField, 'field_type_id', 1)
                this.$set(section.module_fields, indexField, moduleField)
                this.$set(tab.module_sections, indexSection, section)
                this.$set(res.module_tabs, indexTab, tab)

              }
            })
          })
          this.forwardModule = res
        })
    },
    data () {
      return {
        doctor: {},
        forwardModule: {},
        forward: {},
        totalCalls: {
          total_week: 0,
          total_month: 0,
          total_year: 0
        }
      }
    },
    methods: {
      
      deleteAccount() {
        this.$swal.fire({
          title: this.$t('Está seguro que desea eliminar este elemento?'),
          icon: 'warning',
          showCancelButton: true
        }).then(function (result) {
          console.log(result)
        });
      },
      editForward(forward) {
        this.forward = forward
      },
      createForward(forward) {
        this.doctor.forwards.push(forward)
        this.forward = {doctor_id: this.doctor.id}
        jQuery('#forwardModalClose').trigger('click')
      },
      updateForward(forward) {
        let index = this.doctor.forwards.findIndex(x => x.id == forward.id)
        if(index > -1) {
          this.$set(this.doctor.forwards, index, forward)
        } else {
          this.doctor.forwards.push(forward)
        }

        this.forward = {doctor_id: this.doctor.id}
        jQuery('#forwardModalClose').trigger('click')
      },
    }
  }
</script>