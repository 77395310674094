import appService from "./appService";

const callsService = {
    search(page, data) {
        return appService.post('/calls/search/' + page, data)
            .then((res) => res.data)
    },
    exportCalls(data) {
        return appService.post('/calls/export', data)
            .then((res) => res.data)
    },
    searchHistory(page, data) {
        return appService.post('/calls/history/' + page, data)
            .then((res) => res.data)
    },
    totalDoctorCalls(doctorId) {
        return appService.get('/doctors/' + doctorId + '/total_calls')
            .then((res) => res.data)
    }
}

export default callsService;