import axios from 'axios'
import configService from '../config.js'
import authHeader from './authHeader.js'

const appService = axios.create({
    baseURL: configService.apiUrl,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        'Authorization': authHeader()
    },
});

appService.interceptors.response.use((response) => {
    return response
}, error => {
    if(error.response.status === 401) {
        window.location.href = "/logout"
    }

    console.warn('Error status', error.response.status)
    // return Promise.reject(error)
    if (error.response) {
        return error.response.data
    } else {
        return Promise.reject(error)
    }
})

export default appService