<template lang="pug">
  .form-check.form-switch
    input.form-check-input(type="checkbox" v-model="localValue" :value="moduleOptions") 
    label.label-check-label {{labelOption}}
</template>
<script>
export default {
  name: 'InputCheckbox',
  props: {
    value: {default: ''},
    moduleOptions: {type: String, default: ''}
  },
  beforeMount() {
    let valueLabel = this.moduleOptions.split(":")
    this.option = valueLabel[0];
    this.labelOption = valueLabel[1];
    this.localValue = this.value
  },
  data () {
    return {
      localValue: [],
      option: '',
      labelOption: ''
    }
  },
  watch: {
      localValue (newValue) {
       this.$emit('input', newValue)
     },
     value (newValue) {
       this.localValue = newValue
     }
  }
}
</script>
<style scoped>
</style>