<template lang="pug">
  .input-color
    input.form-control.form-control-color(type="color" v-model="localValue")
</template>
<script>
export default {
  name: 'InputColor',
  props: {
    value: {type: String, default: ''},
  },
  data () {
    return {
      localValue: '',
    }
  },
  beforeMount() {
    this.localValue = this.value
  },
  watch: {
      localValue (newValue) {
       this.$emit('input', newValue)
     },
     value (newValue) {
       this.localValue = newValue
     }
  }
}
</script>
<style scoped>
</style>