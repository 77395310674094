<template lang="pug">
  .login
    .row
      .col-md-4
      .col-md-4
        .card
          .card-body
            form(name='form' @submit.prevent='login')
              .form-group
                label(for='username') {{$t('Usuario')}}
                input.form-control(v-model='username' type='text' name='username')
              .form-group
                label(for='password') {{$t('Password')}}
                input.form-control(v-model='password' type='password' name='password')
              hr
              .d-grid.gap-2
                button.btn.btn-primary(:disabled='loading')
                  span.spinner-border.spinner-border-sm(v-show='loading')
                  span {{ $t('Login') }}
              .form-group
                .alert.alert-danger(v-if='message' role='alert') {{message}}

</template>

<script>

export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      message: '',
      username: '',
      password: '',
      user: {}
    }
  },
  beforeCreate() {
    if(localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user'))
      console.log(this.user)
      if(this.user.user.user_type_id < 4) {
        this.$router.push('/doctors');
      } else {
        this.$router.push('/doctors/me');
      }
    }

    document.title = "Top Doctors"
  },
  methods: {
    login() {
      
      if (this.username && this.password) {
        this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        }).then(
            () => {
              location.reload()              
            },
            error => {
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            }
          );
      }
    }
  }

}
</script>

<style lang="scss">
  
</style>
