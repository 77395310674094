import Vue from 'vue'
import callsService from '../services/api/calls'

const callModule = {
    state: {
        registers: [],
        registerFilter: '',
        registerSortColumn: 'id',
        registerSortDirection: 1
    },
    mutations: {
        setRegisters (state, registers) {
            state.registers = registers
        },
        addRegisters (state, register) {
            let index = state.registers.findIndex(x => x.id === register.id)

            if(index == -1) {
                state.registers.push(register)
            }
        },
        updateRegisters (state, register) {
            let index = state.registers.findIndex(x => x.id === register.id)

            if(index > -1) {
                Vue.set(state.registers, index, register)
            }
        },
        deleteRegisters (state, register) {
            let index = state.registers.findIndex(x => x.id === register.id)

            if(index > -1) {
                state.registers.splice(index,1)
            }
        },
        setRegiste4rSortColumn (state, sortColumn) {
            state.registerSortColumn = sortColumn
        },
        setRegisterSortDirection (state, sortDirection) {
            state.registerSortDirection = sortDirection
        }
    },
    actions: {
        searchCalls (context, payload) {
            return new Promise((resolve, reject) => {
                callsService.search(payload.page, payload.data)
                    .then((registers) => {
                        context.commit('setRegisters', registers.data)
                        resolve(registers)
                    })
                    .catch(reject)
            })
        },
        exportCalls (context, payload) {
            return new Promise((resolve, reject) => {
                callsService.exportCalls(payload.data)
                    .then((registers) => {
                        resolve(registers)
                    })
                    .catch(reject)
            })
        },
        searchHistoryCalls (context, payload) {
            return new Promise((resolve, reject) => {
                callsService.searchHistory(payload.page, payload.data)
                    .then((registers) => {
                        context.commit('setRegisters', registers.data)
                        resolve(registers)
                    })
                    .catch(reject)
            })
        },
        totalDoctorCalls (context, payload) {
            return new Promise((resolve, reject) => {
                callsService.totalDoctorCalls(payload.doctorId)
                    .then((registers) => {
                        resolve(registers)
                    })
                    .catch(reject)
            })
        },
    }
}

export default callModule