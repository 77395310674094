<template lang="pug">
  .module-edit
    draw-form(
      :module="currentModule" 
      :value="object"
    )
</template>

<script>
  import DrawForm from '../../components/forms/Draw.vue'

  export default {
    name: 'AccountEdit',
    components: {
      DrawForm
    },
    beforeMount() {
      this.id = this.$route.params.id
      this.moduleUrl = this.$route.params.moduleUrl
      this.$store.dispatch("getModuleByUrl", {moduleUrl: this.moduleUrl})
        .then((res) => {
          this.currentModule = res
        })
      this.$store.dispatch("getRegister", {moduleUrl: this.moduleUrl, id: this.id})
        .then((res) => {
          this.object = res
        })
    },
    data () {
      return {
        id: 0,
        moduleUrl: '',
        currentModule: {},
        object: {}
      }
    },
    methods: {
      
    }
  }
</script>